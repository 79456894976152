<template>
  <div class="container-center-horizontal">
    <div class="scan screen">
      <div class="overlap-group2">
        <div class="vertical-stories">
          <div class="stories-3">
            <div class="overlap-group">
              <div class="background"></div>
              <div class="graphic-elements">
                <img class="rectangle-10" src="@/assets/rectangle-26@1x.png" />
                <div class="text-27 raleway-black-selago-40px">
                  <i18n path="global.title" />
                </div>
              </div>
              <div class="your-image-here border-4px-perano">
                <qrcode-stream
                  @decode="onReaderDecode"
                  @init="onInitReader"
                  :track="paintBoundingBox"
                >
                  <progress v-show="loading" class="pure-material-progress-circular center" />
                </qrcode-stream>
              </div>
              <div class="text">
                <div class="nosken-sertifiktu">
                  <i18n path="scan.line1" />
                </div>
                <div class="text-28 raleway-light-eerie-black-36px">
                  <i18n path="scan.line2" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
    <ErrorDialog
      v-model="d_error"
      @close="$router.push('/preview')"
      :errorText="errorText"
      :errorCode="errorCode"
    />
  </div>
</template>

<script>
import { QrcodeStream /*, QrcodeCapture*/ } from "vue-qrcode-reader";
import Footer from "@/components/Footer.vue";

export default {
  name: "Scan",
  components: { Footer, QrcodeStream },
  data() {
    return {
      loading: true,
      d_error: false,
      errorText: null,
      errorCode: null,
    };
  },
  methods: {
    paintBoundingBox(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const {
          boundingBox: { x, y, width, height },
        } = detectedCode;

        ctx.lineWidth = 4;
        ctx.strokeStyle = "#007bff";
        ctx.strokeRect(x, y, width, height);
      }
    },
    async onInitReader(promise) {
      try {
        this.loading = true;
        await promise;
      } catch (error) {
        this.d_error = true;
        this.errorText = this.$t("error.camera");
        this.errorCode = error.name ? error.name : error;
        console.error(this.errorCode);
      } finally {
        this.loading = false;
      }
    },
    onReaderDecode(result) {
      try {
        if (!result.startsWith("HC1:")) throw 1;
        this.$store.dispatch("LOAD_PASS_DATA", result).catch((error) => {
          this.d_error = true;
          this.errorText = this.$t("error.api");
          this.errorCode = error;
          console.error(this.errorCode);
        });
      } catch (error) {
        this.d_error = true;
        this.errorText = this.$t("error.qrcode");
        this.errorCode = error.name ? error.name : error;
        console.error(this.errorCode);
      }
    },
  },
};
</script>

<style scoped>
/* screen - scan */

.scan {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  overflow: hidden;
  width: 1080px;
}

.scan .overlap-group2 {
  height: 1920px;
  margin-left: -1px;
  position: relative;
  width: 1082px;
}

.scan .vertical-stories {
  display: flex;
  height: 1920px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1081px;
}

.scan .stories-3 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  min-width: 1081px;
}

.scan .overlap-group {
  height: 1920px;
  position: relative;
  width: 1081px;
}

.scan .background {
  background-color: var(--link-water);
  height: 1920px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1080px;
}

.scan .graphic-elements {
  align-items: flex-start;
  background-color: #17379980;
  display: flex;
  height: 1323px;
  left: 1px;
  min-width: 1080px;
  padding: 66px 18px;
  position: absolute;
  top: 0;
}

.scan .rectangle-10 {
  height: 144px;
  object-fit: cover;
  width: 144px;
}

.scan .text-27 {
  letter-spacing: 12px;
  line-height: 64px;
  margin-left: 14px;
  margin-top: 93.67px;
  min-height: 64px;
  min-width: 718px;
  text-align: center;
  white-space: nowrap;
}

.scan .your-image-here {
  background-color: var(--white);
  height: 984px;
  left: 113px;
  position: absolute;
  top: 446px;
  width: 856px;
}

.scan .text {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 182px;
  min-height: 1471px;
  padding: 8px 0;
  position: absolute;
  top: 303px;
  width: 720px;
}

.scan .nosken-sertifiktu {
  color: var(--selago);
  font-family: var(--font-family-cormorant_garamond);
  font-size: 72px;
  font-style: italic;
  letter-spacing: 0.36px;
  line-height: 64px;
  margin-left: 2.16px;
  margin-top: 4px;
  min-height: 90px;
  min-width: 502px;
  text-align: center;
  white-space: nowrap;
}

.scan .text-28 {
  height: 268px;
  letter-spacing: 2.7px;
  line-height: 56px;
  margin-top: 1093px;
  text-align: center;
  width: 720px;
}

.scan .component-1-4 {
  align-items: flex-end;
  display: flex;
  height: 118px;
  left: 1px;
  min-width: 1080px;
  position: absolute;
  top: 1802px;
}

.scan .overlap-group1 {
  height: 120px;
  margin-bottom: -2px;
  position: relative;
  width: 1080px;
}

.scan .rectangle-30 {
  background-color: var(--link-water);
  height: 118px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1080px;
}

.scan .text-29 {
  left: 184px;
  letter-spacing: 0.1px;
  line-height: 64px;
  position: absolute;
  top: 13px;
  white-space: nowrap;
}

.scan .text-30 {
  left: 609px;
  letter-spacing: 0.1px;
  line-height: 64px;
  position: absolute;
  top: 13px;
  white-space: nowrap;
}

.scan .copyright {
  left: 447px;
  letter-spacing: 0.1px;
  line-height: 64px;
  position: absolute;
  top: 56px;
  white-space: nowrap;
}

.scan .line-1 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1080px;
}

/*//////*/
.center {
  position: absolute;
  left: 40%;
  top: 40%;
}
.pure-material-progress-circular {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  border: none;
  border-radius: 50%;
  padding: 0.25em;
  width: 15em;
  height: 15em;
  color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
  background-color: transparent;
  font-size: 15px;
  overflow: hidden;
}

.pure-material-progress-circular::-webkit-progress-bar {
  background-color: transparent;
}

/* Indeterminate */
.pure-material-progress-circular:indeterminate {
  -webkit-mask-image: linear-gradient(transparent 50%, black 50%),
    linear-gradient(to right, transparent 50%, black 50%);
  mask-image: linear-gradient(transparent 50%, black 50%),
    linear-gradient(to right, transparent 50%, black 50%);
  animation: pure-material-progress-circular 6s infinite
    cubic-bezier(0.3, 0.6, 1, 1);
}

:-ms-lang(x),
.pure-material-progress-circular:indeterminate {
  animation: none;
}

.pure-material-progress-circular:indeterminate::before,
.pure-material-progress-circular:indeterminate::-webkit-progress-value {
  content: "";
  display: block;
  box-sizing: border-box;
  margin-bottom: 0.25em;
  border: solid 0.5em transparent;
  border-top-color: currentColor;
  border-radius: 50%;
  width: 100% !important;
  height: 100%;
  background-color: transparent;
  animation: pure-material-progress-circular-pseudo 0.75s infinite linear
    alternate;
}

.pure-material-progress-circular:indeterminate::-moz-progress-bar {
  box-sizing: border-box;
  border: solid 0.5em transparent;
  border-top-color: currentColor;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-color: transparent;
  animation: pure-material-progress-circular-pseudo 0.75s infinite linear
    alternate;
}

.pure-material-progress-circular:indeterminate::-ms-fill {
  animation-name: -ms-ring;
}

@keyframes pure-material-progress-circular {
  0% {
    transform: rotate(0deg);
  }
  12.5% {
    transform: rotate(180deg);
    animation-timing-function: linear;
  }
  25% {
    transform: rotate(630deg);
  }
  37.5% {
    transform: rotate(810deg);
    animation-timing-function: linear;
  }
  50% {
    transform: rotate(1260deg);
  }
  62.5% {
    transform: rotate(1440deg);
    animation-timing-function: linear;
  }
  75% {
    transform: rotate(1890deg);
  }
  87.5% {
    transform: rotate(2070deg);
    animation-timing-function: linear;
  }
  100% {
    transform: rotate(2520deg);
  }
}

@keyframes pure-material-progress-circular-pseudo {
  0% {
    transform: rotate(-30deg);
  }
  29.4% {
    border-left-color: transparent;
  }
  29.41% {
    border-left-color: currentColor;
  }
  64.7% {
    border-bottom-color: transparent;
  }
  64.71% {
    border-bottom-color: currentColor;
  }
  100% {
    border-left-color: currentColor;
    border-bottom-color: currentColor;
    transform: rotate(225deg);
  }
}
</style>